.lesson-content {
    color: white;
}

.lesson-content p {
    margin-block: 16px;
}

.lesson-content h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: bold;
}

.lesson-content :not(pre)>code[class*=language-],
.lesson-content pre[class*=language-] {
    background: #f5f2f0;
    color: black;
}

.lesson-content pre[class*=language-] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
}

.lesson-content code {
    background-color: #e8e8e8;
    border-radius: 3px;
    padding: 0.1rem 0.2rem;
}

.lesson-content user agent stylesheet code {
    font-family: monospace;
}

.lesson-content ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.lesson-content iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16 / 9;
}
.whiteArrowSelect {
    color: white;

    & .MuiSelect-icon {
        /* This targets the dropdown arrow specifically */
        color: white;
        /* Change the arrow color */
    }
}

.button-language {
    @apply items-center text-2xl bg-[#202936] rounded-lg text-gray-50 hover:text-opacity-70;
}

nav {
    transition: background-color 0.3s, box-shadow 0.3s, padding 0.5s;
}

.Btn {
    --black: #000000;
    --ch-black: #141414;
    --eer-black: #1b1b1b;
    --night-rider: #2e2e2e;
    --white: #ffffff;
    --af-white: #f3f3f3;
    --ch-white: #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border: none;
    /*  border-radius: 5px; */
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    /*  background-color: #ef4444; */
    border: 1px solid #ef4444;
}

/* plus sign */
.sign {
    width: 100%;
    transition-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign svg {
    width: 17px;
}

.sign svg path {
    fill: #ef4444;
}

/* text */
.text {
    position: absolute;
    right: 0%;
    width: 0%;
    opacity: 0;
    color: #ef4444;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 13px;
    font-weight: 700;
    transition-duration: .3s;
}

/* hover effect on button width */
.Btn:hover {
    width: 125px;
    /*   border-radius: 5px; */
    transition-duration: .3s;
}

.Btn:hover .sign {
    width: 30%;
    transition-duration: .3s;
    padding-left: 10px;
}

/* hover effect button's text */
.Btn:hover .text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 5px;
}

/* button click effect*/
.Btn:active {
    transform: translate(2px, 2px);
}

.menu-button {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.show-menu .menu-button {
    opacity: 1;
}

.menu-button:nth-child(1) {
    transition-delay: 0.1s;
}

.menu-button:nth-child(2) {
    transition-delay: 0.2s;
}

.menu-button:nth-child(3) {
    transition-delay: 0.3s;
}

.menu-button:nth-child(4) {
    transition-delay: 0.4s;
}

.menu-button:nth-child(5) {
    transition-delay: 0.5s;
}

.menu-button:nth-child(6) {
    transition-delay: 0.6s;
}

.menu-button:nth-child(7) {
    transition-delay: 0.7s;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-VariableFont_slnt,wght.ttf")
    format("truetype");
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
}

@font-face {
  font-family: "TitilliumWeb-Black";
  src: url("../public/fonts/TitilliumWeb/TitilliumWeb-Black.ttf");
}

body {
  margin: 0;
  background-color: #02021c;
  font-family: "Inter", sans-serif;
}

/* For webkit-based browsers (Chrome, Safari) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* CUSTOM SCROLLBAR */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #02021c;
}

::-webkit-scrollbar-thumb {
  background: #a892ff;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #6753b4;
}

/* CUSTOM SCROLLBAR END*/

.containerNavbar {
  @apply min-h-[90vh] mt-[132px] w-full max-w-[1440px] px-[40px] md:px-[120px] flex flex-col self-center;
}

.dionamiteContainer {
  @apply w-full max-w-[1440px] px-[40px] md:px-[120px] flex flex-col self-center;
}

footer {
  box-shadow: 0px -48px 128px -56px #a892ff, 0px -228px 32px -224px #b09fef;
}

.gradient-hero {
  background: linear-gradient(to bottom, #ffffff, #f3f3f3a1);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* Makes the text take the color of the background */
  padding-bottom: 2px;
}

/* Used for every title */
.title {
  @apply font-bold text-white md:text-2xl lg:text-3xl xl:text-4xl sm:tracking-wider;
}

/* Used for every subtitle */
.subtitle {
  @apply text-lg font-bold tracking-wide lg:text-xl xl:text-2xl;
}

/* Used for every input field */
.inputField {
  @apply px-3 py-2 text-sm border border-gray-400 rounded-md outline-gray-600 md:text-base;
}

/* Used for every link */
.link {
  @apply font-bold transition-all cursor-pointer hover:underline;
}

/* Used for every button that represents an neutral action, not positive or negative ones */
.buttonAction {
  @apply px-4 py-3 font-bold tracking-wider text-white transition-all rounded-lg bg-gradient-to-r from-rose-600 to-primary hover:scale-95;
}

.buttonActionDisabled {
  @apply px-4 py-3 font-bold tracking-wider text-white transition-all bg-[#f099f8] rounded-lg cursor-not-allowed;
}

.positiveButton {
  @apply px-4 py-3 font-bold tracking-wider text-white transition-all bg-green-500 rounded-lg hover:bg-green-600;
}

.positiveButtonDisabled {
  @apply px-4 py-3 font-bold tracking-wider text-white transition-all bg-green-200 rounded-lg cursor-not-allowed;
}

.negativeButton {
  @apply px-4 py-3 font-bold tracking-wider text-white transition-all bg-red-500 rounded-lg hover:bg-red-700;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.h1-border-bottom {
  padding-bottom: 32px;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(
    90deg,
    #6753b4 0%,
    rgba(61, 46, 119, 0) 88.02%
  );
  border-image-slice: 1;
}

@media (min-width: 640px) {
  .h1-border-bottom {
    width: 481px;
  }
}

.learn-border-left {
  position: relative;
}

.learn-border-left:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background: linear-gradient(180deg, #ffffff33 0%, #99999900 100%);
  z-index: 1;
}

.learn-border-left:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  background-color: #6753b4;
  z-index: 2;
  height: 24px;
}

.learn-bottom-gradient {
  position: relative;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  margin-top: 65px;
  background: radial-gradient(
      40.67% 50.67% at 50% 0%,
      rgba(136, 119, 200, 0.47) 0%,
      rgba(2, 2, 28, 0) 100%
    ),
    radial-gradient(
      20% 10% at 50% 0%,
      rgba(174, 153, 255, 0.12) 0%,
      rgba(174, 153, 255, 0) 100%
    ),
    radial-gradient(
      10.49% 10.49% at 50% 0%,
      #ae99ff 0%,
      rgba(174, 153, 255, 0) 100%
    );
  border-top: 1px solid #6753b4;
}

.learn-bottom-gradient:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 88px;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(204, 191, 255, 0) 0%,
    #dfd7ff 52%,
    rgba(123, 115, 153, 0) 100%
  );
  box-shadow: 0px 4px 4px 0px #00000040;
}

.circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* Makes the div circular */
  background: linear-gradient(180deg, #8f7fcc 0%, #ccbfff 100%);
  /* Background gradient */
  box-shadow: 0px 4px 4px 0px #ae99ff,
    /* First layer of shadow */ 0px 8px 24px 0px #9377ff;
  /* Second layer of shadow */
  display: flex;
  /* Optional: centers any content inside the circle */
  align-items: center;
  /* Optional: vertical alignment for content */
  justify-content: center;
  /* Optional: horizontal alignment for content */
}

.border-left-testimonial {
  border-left: 1px solid;
  border-image-source: linear-gradient(
    180deg,
    #a38cfa 0%,
    rgba(101, 81, 177, 0) 100%
  );
  border-image-slice: 1;
  height: 100%;
}

.slider1 .slick-track {
  margin-left: -60px;
  /* Shifts the first slider's track to the left */
}

.slider2 .slick-track {
  margin-left: 30px;
  /* Shifts the second slider's track to the right */
}

@media (max-width: 768px) {
  .slider1 .slick-track,
  .slider2 .slick-track {
    margin-left: 0px;
    /* Sets margin-left to 0px for both sliders */
  }
}

/* Default opacity for all slides */
/* .slick-slide {
  opacity: 0.3;
  transition: opacity 0.5s ease;
} */

.active-slide {
  opacity: 1;
}

.inactive-slide {
  opacity: 0.3;
  transition: opacity 0.5s ease;
}

.brightening-circle {
  /*   width: 45%;
  height: 140%; */
  width: 700px;
  height: 750px;
  position: absolute;
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  border-radius: 50%;
  /* Ensures the div is circular */
  z-index: 10;
  /* Ensures it's above other content */
  overflow: hidden;
  /* Ensures no overflow from the content */
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2) 45%,
    rgba(255, 255, 255, 0.05) 55%,
    rgba(255, 255, 255, 0) 65%
  );
  mix-blend-mode: soft-light;
  user-select: none;
  pointer-events: none;
}

.shake-text {
  display: inline-block;
  animation: shake 2.8s ease infinite;
}

@media (max-width: 768px) {
  .brightening-circle {
    width: 400px;
    height: 450px;
  }
}

@media (max-width: 640px) {
  .brightening-circle {
    width: 350px;
    height: 400px;
  }
}

.hover\:shadow-card:hover {
  box-shadow: 0px 4px 12px 0px #6753b4;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(50%) sepia(100%) saturate(500%) hue-rotate(200deg);
  /* Adjust the filter values as needed to get the desired color */
}

input[type="date"]::-ms-expand {
  filter: invert(50%) sepia(100%) saturate(500%) hue-rotate(200deg);
  /* Adjust the filter values as needed to get the desired color */
}

.cartcard {
  box-shadow: 0px 0px 6px 0px #6753b4;
}
